<template>
  <div class="news-detail-page">
<!--    <div class="news-detail-head">-->
<!--      <div class="news-detail-title">-->
<!--        {{ activityDetail.title }}-->
<!--      </div>-->
<!--      <div class="news-detail-author">-->
<!--       {{ activityDetail.creatTime }}-->
<!--      </div>-->
<!--    </div>-->
    <!-- <div class="news-detail-banner">
      <div class="news-detail-image">
        <el-carousel trigger="click" height="3.78rem">
          <el-carousel-item v-for="(item,index) in activityDetail.image" :key="index">
            <el-image
                style="width: 6.86rem; height: 3.78rem"
                :src="item"
                fit="contain"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div> -->
    <div class="news-detail-body">
      <div class="news-detail-content">
        <div class="ql-editor" v-html="activityDetail.content"></div>
      </div>
		<div class="mark">活动时间：{{activityDetail.activityTime}}</div>
		<div class="news-detail-content">
			<div class="mark">活动地点：{{activityDetail.place}}</div>
			<div class="mark">报名开始时间：{{activityDetail.staTime}}</div>
			<div class="mark">报名截止时间：{{activityDetail.endTime}}</div>
		</div>
    </div>
    <div v-if="isDuringDate" class="submit-active" @click="onSubmit('form')">
      <div class="submit-button-active">
        报名
      </div>
    </div>
  </div>
</template>

<script>
import {getActivityDetail, getSignatureAll} from "@/common/api";
import { wexinShare } from '@/common/utils';
export default {
  data() {
    return {
      keyword: '',
      isDuringDate: false,
      activityDetail: {},
      wxConfigId: this.$route.query.wxConfigId
    }
  },
  created: function () {
    this.getDetail(this.$route.query.id)
  },
  methods: {
    initWxJs() {
      // let signUrl = encodeURIComponent(window.location.href.split('#')[0]);
      let signUrl = window.location.href.split('#')[0];
      // let signUrl = 'http://chambermp.bjyulinkeji.com/activityDetail';
      // const getPath = sessionStorage.getItem("usePath")
      // const signUrl = getPath.split('#')[0]
      getSignatureAll({
        url: signUrl,
        wxConfigId: this.$route.query.wxConfigId
        // appId: localStorage.getItem('appid')
      }).then(res => {
        if (res.data.code === 200) {
          console.log(res.data.data.appId,'res.data')
          const obj = {
            appId: res.data.data.appId,
            nonceStr: res.data.data.nonceStr,
            signature: res.data.data.signature,
            timestamp: res.data.data.timestamp,
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
          }
          let shareData = {
            title: this.activityDetail.title, // 分享标题
            desc:this.activityDetail.place,
            // link: res.data.data.url,
            link: window.location.href.split('#')[0],
            imgUrl: this.activityDetail.image // 分享图标
          };
          //引用
          console.log('引用')
          wexinShare(obj, shareData);
        } else if (res.data.code === 401) {
          sessionStorage.removeItem('Authorization');
        } else {
          this.$toast(res.data.msg || "配置信息错误", {
            durtaion: 200
          });
        }
      })
    },
    getUrlCode() { // 截取url中的code方法
      const url = location.search;
      this.winUrl = url
      const theRequest = new Object();
      if (url.indexOf("?") !== -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      return theRequest
    },
    getDetail(id) {
      getActivityDetail(id).then(res => {
        const regex = new RegExp('<img', 'gi')
        res.data.data.content = res.data.data.content.replace(regex, `<img style="max-width: 100%; height: auto"`);
        this.activityDetail = res.data.data;

        let curDate = new Date();
        this.isDuringDate = curDate >= new Date(this.activityDetail.staTime.replace(/-/g, '/')) && curDate <= new Date(this.activityDetail.endTime.replace(/-/g, '/'));
        this.initWxJs()
      });
    },
    onSubmit(){
      // 将活动Id保存至缓存中，在报名时取出
      localStorage.setItem("activityId", this.activityDetail.activityId)
      this.$router.push({
        path: '/activity', query: {
          wxConfigId: this.wxConfigId,
          activityId: this.activityDetail.activityId,
        }
      })
      // this.$router.push({path:'/activity', params: { wxConfigId: this.wxConfigId }})
    },
  }
}
</script>

<style scoped>
.news-detail-page {
  height: 100vh;
  overflow: scroll;
}

.news-detail-head {
  padding: 0.36rem;
}

.news-detail-title {
  font-size: 0.52rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.news-detail-author {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin: 0.2rem 0;
}

.news-detail-banner {
  padding: 0 0.36rem;
}

.news-detail-body {
  padding:0.36rem;
  margin-bottom: 1rem;
}

.news-detail-content {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.submit-active {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.submit-button-active {
  width: 86%;
  height: 1rem;
  background: #DDB06C;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #FFFFFF;
  letter-spacing: 0.1rem;
}
.mark{
	text-align: left;
	margin: 10px 0;
	font-size: 14px;
	font-family: PingFangSC-Medium, PingFang SC;
}
.ql-editor {
	max-width: 100%;
	image{
    max-width: 100%;
    max-height: 100%;
  }

}
/deep/ .ql-editor p{
	max-width:100% !important;
	word-break:break-all;
	word-wrap: break-word;
	white-space: normal;
}
</style>
